body {
  font-family: 'Roboto', sans-serif;
  background-color: #0B864B;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  text-align:center;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  color: #333;
}

p {
  color: white;
}

.input {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1.5px solid #0e0c0c;
  margin-bottom: 20px;
}

select {
  margin-left: 10px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1.5px solid #0e0c0c;
}

.currency-image {
  width: 250px; 
  height: auto; 
  margin-bottom: -2rem;
}

.currency-label {
  font-weight: bold;
  color: white;
  margin-right: 10px;
  margin-bottom: 3rem;
}

.disclaimer {
  font-size: 12px; 
  margin-top: -5px; 
}

.footer {
  text-align: center;
  padding: 10px 0;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
